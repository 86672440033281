<!-- eslint-disable vue/no-v-html -->
<template>
    <v-data-table v-bind="$attrs" class="elevation-1" :headers="headers" :items="vendors" :loading="loading" :pagination="pagination" @click.native.stop @update:pagination="$emit('update:pagination', $event)">
        <template v-slot:items="{ item: vendor }">
            <tr
                :class="{
                    'crossed--text red--text': vendor.deleted_at,
                    pointer: true
                }"
                @click="onClickOnLine(vendor.id)">
                <td v-html="$highlightMatching(vendor.company, search)" />
                <td v-if="displayOwner">{{ vendor.accounting_firm_name }}</td>
                <td v-html="$highlightMatching(vendor.siret, search)" />
                <td v-html="$highlightMatching(vendor.client_code, search)" />
                <td>{{ vendor.closing_date }}</td>
                <template v-if="extended">
                    <td>{{ vendor.email }}</td>
                    <td>{{ vendor.naf_code }}</td>
                    <td>{{ vendor.capital }}</td>
                    <td>{{ vendor.rcs }}</td>
                    <td>{{ vendor.mission_letter_date }}</td>
                    <td>{{ vendor.society_type }}</td>
                    <td>{{ vendor.fiscal_status }}</td>
                    <td>{{ vendor.fiscal_regime }}</td>
                    <td>{{ vendor.tva_type }}</td>
                    <td>{{ vendor.tva_period }}</td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.fiscal_account" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.tvts" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.ifu" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.das2" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.cfe" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.dsi" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.irpp" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.decloyer" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                    <td>
                        <v-checkbox color="primary" disabled hide-details :input-value="vendor.cvae" readonly :ripple="false" style="margin:auto;width:24px;" />
                    </td>
                </template>
            </tr>
         </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'CustomersTable',
    props: {
        displayOwner: {
            default: false,
            required: false,
            type: Boolean
        },
        extended: {
            default: false,
            required: false,
            type: Boolean
        },
        loading: {
            default: false,
            required: false,
            type: Boolean
        },
        pagination: {
            default: null,
            required: false,
            type: Object
        },
        search: {
            default: null,
            required: false,
            type: String
        },
        value: {
            default: () => ([]),
            required: false,
            type: Array
        }
    },
    computed: {
        headers: function () {
            let result = [
                {
                    text: this.$t('customers.labels.customer'),
                    value: 'company'
                }
            ]

            if (this.displayOwner) {
                result.push({
                    text: this.$t('customers.labels.workspace'),
                    value: 'accounting_firm_name'
                })
            }

            result = result.concat([
                {
                    text: this.$t('customers.labels.siret'),
                    value: 'siret'
                },
                {
                    text: this.$t('customers.labels.client_code'),
                    value: 'client_code'
                },
                {
                    text: this.$t('customers.labels.closing_date'),
                    value: 'closing_date'
                }
            ])

            if (this.extended) {
                result = result.concat([
                    {
                        sortable: false,
                        text: this.$t('customers.labels.contact')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.naf_code')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.capital')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.rcs')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.mission_letter_date')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.society_type')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.fiscal_status')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.fiscal_type')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.tva_type')
                    },
                    {
                        sortable: false,
                        text: this.$t('customers.labels.tva_period')
                    },
                    {
                        align: 'center',
                        sortable: false,
                        text: this.$t('customers.labels.fiscal_account'),
                    },
                    {
                        text: this.$t('customers.labels.tvts'),
                        value: 'details.tvts'
                    },
                    {
                        text: this.$t('customers.labels.ifu'),
                        value: 'details.ifu'
                    },
                    {
                        text: this.$t('customers.labels.das2'),
                        value: 'details.das2'
                    },
                    {
                        text: this.$t('customers.labels.cfe'),
                        value: 'details.cfe'
                    },
                    {
                        text: this.$t('customers.labels.dsi'),
                        value: 'details.dsi'
                    },
                    {
                        text: this.$t('customers.labels.irpp'),
                        value: 'details.irpp'
                    },
                    {
                        text: this.$t('customers.labels.decloyer'),
                        value: 'details.decloyer'
                    },
                    {
                        text: this.$t('customers.labels.cvae'),
                        value: 'details.cvae'
                    }
                ])
            }

            return result
        },
        vendors: function () {
            return this.value.map(vendor => {
                const result = {
                    accounting_firm_name: vendor.accounting_firm?.name,
                    capital: vendor.details?.capital,
                    cfe: vendor.details?.cfe,
                    client_code: vendor.client_code,
                    company: vendor.company,
                    cvae: vendor.details?.cvae,
                    das2: vendor.details?.das2,
                    decloyer: vendor.details?.decloyer,
                    deleted_at: vendor.deleted_at,
                    dsi: vendor.details?.dsi,
                    email: vendor.email,
                    fiscal_account: vendor.details?.fiscal_account ?? false,
                    fiscal_regime: vendor.details?.fiscal_type,
                    fiscal_status: vendor.details?.fiscal_statut,
                    id: vendor.id,
                    ifu: vendor.details?.ifu,
                    irpp: vendor.details?.irpp,
                    naf_code: vendor.naf,
                    rcs: vendor.details?.rcs_city,
                    siret: vendor.siret,
                    society_type: vendor.details?.society_type,
                    tva_period: vendor.details?.tva_period,
                    tva_type: vendor.details?.tva_type,
                    tvts: vendor.details?.tvts
                }

                if (vendor.details?.closing_date) {
                    const closingDate = vendor.details.closing_date.substring(0, 10)
                    // eslint-disable-next-line no-unused-vars
                    const [year, month, day] = closingDate.split('-')
                    result.closing_date = `${day}/${month}`
                } else {
                    result.closing_date = null
                }
                if (vendor.details?.mission_letter_date) {
                    result.mission_letter_date = this.$translateDateTime(vendor.details.mission_letter_date, 'tiny', true)
                } else {
                    result.mission_letter_date = null
                }

                return result
            })
        }
    },
    methods: {
        onClickOnLine: function (vendorId) {
            const vendor = this.value.find(vendor => vendor.id == vendorId)
            this.$emit('click-on-line', vendor)
        }
    }
}
</script>